.dashboard-container{
    min-height: 80vh;
}

.dashboard-card{
    max-width: 25rem;
    min-height: 20vh;
    color: #fff;
    background-color: #000;
    overflow-y: scroll;
}

.bg-orange{
    background-color: #F8971C;
}

.sticky-notes-btn{
    border: none;
    background: none;
}

.sticky-notes-body{
    background-color: #F8971C;
}

.text-area{
    max-width: 500px;
    min-height: 70vh;
    max-height: 70vh;
    overflow: scroll !important;
}
.sticky-notes{
    border: none;
    background: none;
    color:#fff;
    resize: none;
}

.fa-plus-square:hover, .fa-times:hover, .fa-pencil-square-o:hover{
    color: #000 !important;
}

.add-notes{
    resize: none;
}
/* 
.sticky-title{
    min-height: ;
    border: 2px solid red;
} */

