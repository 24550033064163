.input-label {
  font-weight: bold;
}

.container {
  min-height: 80vh;
  height: 100%;
  border-radius: 20px;
  background-color: #f8971c4d;
  width: 50%;
}

.input-tag {
  background-color: rgba(248, 151, 28, 0.8);
  border: none;
  border-radius: 50px;
  padding: 5px 10px;
  color: #fff;
}

.profile-image {
  border-radius: 200px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}
