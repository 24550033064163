.btn-round {
  background-color: #f8971c !important;
  color: #fff !important;
  border-radius: 6px;
}

.btn-delete {
  color: #fff !important;
  border-radius: 6px;
  background-color: transparent;
  border: none;
}



.boxMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.box {
  width: 260px;
  height: 100px;
  background: transparent;
  border: 1px solid #f8971c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  text-align: center;
  border-radius: 6px;
}

.title {
  font-weight: bold;
}

.value {
  font-size: 32px;
  font-weight: 800;
}

@media (max-width: 768px) {
  .btn-groups {
    display: flex;
    flex-direction: column;
  }

  .req-btn {
    margin-right: 0px;
  }

  .boxMain {
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 100%;
    margin: 10px 0;
  }
}

.text-detail {
  color: black !important;
  text-align: left !important;
}

.leave-table-container {
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
  max-width: 100%; /* Ensure the table does not exceed the screen width */
}

.leave-table {
  width: 100%;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  /* background-color: #F8971C; */
  color: black;
  border: none;
  padding: 20px 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

tbody tr.even-row {
  background-color: #f7f7f7; /* Light grey */
  color: black;
  font-size: 16px;
  font-weight: lighter !important;
}

tbody tr.odd-row {
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: lighter !important;
}

td {
  border: none;
  padding: 20px 10px;
  border-bottom: 1px solid #ccc;
  text-transform: capitalize;
  font-weight: 400 !important;
}

.fa-dot-circle {
  margin-right: 10px;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.pagination-button {
  border: none;
  background-color: #ebe9e9;
  color: #333;
  cursor: pointer;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s;
}

.pagination-button.active {
  background-color: #f8971c;
  color: white;
}

.pagination-button:hover:not(.active) {
  background-color: #f8971c;
  color: white;
  transition: background-color 0.3s;
}

/* Disable styles for disabled buttons */
.pagination-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-link {
  border: none;
  background-color: transparent;
  color: #333;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s;
}

.pagination-link.active {
  color: #f8971c;
}

.pagination-link:hover:not(.active) {
  color: #f8971c;
  transition: background-color 0.3s;
}

/* Disable styles for disabled buttons */
.pagination-link[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
  color: #333;
}

.pagination-link[disabled]:hover {
  opacity: 0.5;
  cursor: not-allowed;
  color: #333;
}

.form-label {
  font-weight: 700 !important;
}

.form-control {
  padding: 0.7rem 0.75rem !important;
}

.submit-button {
  padding: 10px 35px !important;
  color: white !important;
  background-color: #f8971c !important;
  /* border-radius: 20px !important; */
  border: none !important;
  border-radius: 10px;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 45%;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}

.dropdown-list li.selected {
  background-color: #f8971c !important; /* Set the background color for the selected option */
}

.drop-btn {
  width: 100% !important;
  background-color: transparent;
  border: none;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #f8971c !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 157, 13, 0.25) !important;
}

.req_to:focus option:hover {
  background-color: #f8971c !important;
}


.mw-400px {
  max-width: 400px;
}

.react-datepicker__input-container>input{
  font-size: 16px !important;
}