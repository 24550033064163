/* Import FontAwesome CSS in your CSS/SCSS file */
@import "~@fortawesome/fontawesome-free/css/all.css";

.btn-round {
  background-color: #f8971c !important;
  color: #fff !important;
}

.leave-table-container {
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
  max-width: 100%; /* Ensure the table does not exceed the screen width */
}

.leave-table {
  width: 100%;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  /* background-color: #F8971C; */
  color: black;
  border: none;
  padding: 20px 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

tbody tr.even-row {
  background-color: #f7f7f7; /* Light grey */
  color: black;
  font-size: 16px;
  font-weight: lighter !important;
}

tbody tr.odd-row {
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: lighter !important;
}

td {
  border: none;
  padding: 20px 10px;
  border-bottom: 1px solid #ccc;
  font-weight: 400 !important;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.pagination-button {
  border: none;
  background-color: #ebe9e9;
  color: #333;
  cursor: pointer;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s;
}

.pagination-button.active {
  background-color: #f8971c;
  color: white;
}

.pagination-button:hover:not(.active) {
  background-color: #f8971c;
  color: white;
  transition: background-color 0.3s;
}

/* Disable styles for disabled buttons */
.pagination-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-link {
  border: none;
  background-color: transparent;
  color: #333;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s;
}

.pagination-link.active {
  color: #f8971c;
}

.pagination-link:hover:not(.active) {
  color: #f8971c;
  transition: background-color 0.3s;
}

/* Disable styles for disabled buttons */
.pagination-link[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
  color: #333;
}

.pagination-link[disabled]:hover {
  opacity: 0.5;
  cursor: not-allowed;
  color: #333;
}

.btn-pop {
  background-color: white;
  border: 1px solid #c4c0c0;
  border-radius: 20px;
  text-transform: capitalize;
}

.fa-caret-down {
  margin-left: 10px;
  color: #c4c0c0;
}

.fa-dot-circle {
  margin-right: 10px;
}

.approved-btns {
  display: flex;
  justify-content: space-evenly;
}

.approved-button {
  padding: 10px 35px !important;
  color: #c4c0c0;
  border: 1px solid #c4c0c0;
  /* border-radius: 20px !important; */
  background-color: transparent;
  border-radius: 10px;
}

.approved-button:hover {
  color: white;
  background-color: black !important;
}

.approved {
  color: #f8971c !important;
  border: 1px solid #f8971c !important;
}

.approved:hover {
  color: white !important;
  background-color: #f8971c !important;
}
