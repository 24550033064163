.logo {
    width: 100px !important;
    height: 100px !important;

}

ul {
    width: 100%;
}

.nav-icons {
    height: 40px;
}

.time,
.orange-italic {
    margin: 0;
    font-weight: bold;
}

.orange-italic {
    color: #F8971C;
}

.iconHeight {
    height: 60px;
}

.remainder {
    height: 40px;
}

.arrow {
    padding-right: 10px;
}

.lunch-footer {
    background-color: rgba(248, 151, 28, 0.2);
    border: 1px solid #F8971C;
    border-radius: 0.375rem;
    height: 10vh;
}

h2 {
    color: #000 !important;
}

tr.table-head th {
    background-color: #ffe4bc;
}

.table-container-lunch {
    border: 1px solid #F8971C;
    border-radius: 0.375rem;
    min-height: 40vh;
    max-height: 100% !important;
    overflow: scroll;
    margin: 0;
    width: 100%;
}

.table-head th {
    /* background: white; */
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(248, 151, 28, 0.8);
    height: 10vh;
}

table {
    text-align: center;
    position: relative;
}

tbody>tr:last-child>td {
    border-bottom: 0 !important;
}

td {
    font-weight: bold;
}

/*************/
.form-check-input {
    background-color: #F8971C33 !important;
    border: 2px solid #000 !important;

}

.form-check-input:checked {
    background-color: #F8971C !important;
    border-color: #000 !important;

}

.form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

@media screen and (max-width:1200px) {
    .date-picker {
        width: 100% !important;
    }

}

.btn-round {
    background-color: #F8971C !important;
    color: #fff;
}

.footer-lunch {
    border-radius: 0.375rem;
    background-color: #F8971C;
    display: flex;
    justify-content: space-between;
    align-items: center;
}