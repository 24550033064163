.fc-toolbar-chunk>div:nth-child(1) {
    display: flex;
    border-radius: 10px;
    background-color: #F8971C;
}

.fc-toolbar-chunk>div:nth-child(1)>.fc-button {
    background: none !important;
    outline: none;
    border: none;
    border-radius: 0 !important;
    box-shadow: none;
}

.fc-toolbar-chunk>div:nth-child(1)>.fc-button:active {
    box-shadow: none !important;
}

.fc-toolbar-chunk>div:nth-child(1)>.fc-button:focus {
    background: none;
}

.fc-icon {
    color: #fff;
}

.fc-toolbar-title {
    color: #fff !important;
    padding: 2px 5px;
}

.fc-prev-button {
    border-right: 3px solid #fff !important;
}

.fc-next-button {
    border-left: 3px solid #fff !important;
}

/* Calender table styling */
.fc-daygrid>table:nth-child(1) {
    border-radius: 20px;
    text-align: center !important;
    background-color: rgba(248, 151, 28, 0.2);
    border: 2px solid rgba(248, 151, 28, 0.2);
    outline: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}


.fc td,
.fc th {
    border: none !important;
}

.fc thead {
    border: 1px solid #000 !important
}

.fc td {
    border-bottom: 1px solid #F8971C !important;
}

.fc tr {
    height: 0px;
}

.fc-daygrid-day-top {
    justify-content: center;
    padding-top: 10px;
}

.fc-daygrid-day-number {
    color: #000;
    font-size: 20px;
}

.fc-col-header-cell-cushion {
    color: #000;
    font-size: 15px;
    font-style: unset;
}

.fc-day-today {
    background-color: rgba(248, 151, 28, 0.4) !important;
}

.input-hour {
    border: 1px solid #F8971C;
    background-color: #0000000A;
    border-radius: 20px;
    height: 35px;
    width: 30%;
}

.input-desc {
    border: 1px solid #F8971C;
    background-color: #0000000A;
}

textarea {
    /* resize: none !important; */
    width: 100% !important;
}

.input-reportTo {
    border: 1px solid #F8971C;
    background-color: #0000000A;
    border-radius: 20px;
}

.submit-hours {
    background-color: #F8971C;
    border-radius: 25px;
    outline: none;
    font-size: 24px;
    width: 30%;
    color: #fff;
    border: none !important;
}

.submit-hours:hover {
    background-color: rgba(248, 151, 28, 0.8);
}

.fc-addHoursButton-button,
.fc-extraTimeRequestButton-button {
    background-color: #F8971C !important;
    border: none !important;
    border-radius: 20px !important;
    font-size: 24px;
    color: #fff;
}

.fc-addHoursButton-button:hover,
.fc-extraTimeRequestButton-button:hover {
    background-color: rgba(248, 151, 28, 0.8) !important;
}

.footer-text {
    text-align: center;
    font-weight: bold;
}

.txt-size {
    margin: 0;
    padding: 0;
    font-size: 30px;
}

.footer-cal {
    border-radius: 20px;
    text-align: center !important;
    background-color: rgba(248, 151, 28, 0.2);
    border: 2px solid rgba(248, 151, 28, 0.2);
    outline: none;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
}

.hours-spinner {
    background-color: #F8971C;
    color: #fff;
    border-radius: 0.375rem;
}

.dropdown-menu {
    max-height: 25vh;
    min-height: 10vh;
    overflow-y: scroll;
}

.bg-zweidevs {
    background-color: #F8971C !important;
}

.no-resize {
    resize: none;
    border: 1px solid #F8971C;
    border-radius: 0.375rem;
}

.fc-event-main {
    cursor: pointer;
}

.fc-daygrid-event:hover {
    opacity: 0.8;
}

.extra-hour-icon {
    border-radius: 200px;
    width: 60px;
    height: 50px;
    object-fit: cover;
}