.sidebar-logo {
    /* width: 100px !important; */
    min-width: 50px !important;
    height: 100px !important;
}

ul {
    width: 100%;
}

.nav-icons {
    height: 40px;
}

.bor-orange{
    border-top: 1px solid #F8971C;
}

.time,
.orange-italic {
    margin: 0;
    font-weight: bold;
}

.orange-italic {
    color: #F8971C;
}

.topnav {
    width: 100%;
    border-bottom: 2px solid black;
}

.remainder {
    height: 40px;
}

.dropdown-item {
    font-style: normal !important;
    font-weight: bold !important;
    color: #000 !important;
    padding: 5px;
    font-size: 12px !important;
}

.dropdown-toggle::after {
    content: none !important;
}

#sidebar {
    /* border:2px solid red; */
    min-width: 170px;
    width: 100%;
    background: #000;
    color: #fff;
    display: block;
    min-height: 100vh;
    overflow: auto;
}
@media screen and (max-height: 101vh) {
    #sidebar{
        height: 100%;
    }
}

.side-nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.nav-time {
    display: flex;
    align-items: center;
    /* z-index: 1000; */
}

.btn-sidebar {
    display: none;
    border: none;
    border-radius: 0 0.375rem 0.375rem 0;
    background-color: #F8971C;
    padding: 3px 10px;
    color: #fff;
    height: max-content;
    z-index: 1000;
    position: relative;
}

.active {
    background-color: #F8971C;
    color: #fff;
}

.border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) #F8971C !important;
}

.btn-primary {
    --bs-btn-hover-bg: rgba(248, 151, 28, 0.8) !important;
    --bs-btn-active-bg: #f8971c !important;
}

.dropdown-menu {
    --bs-dropdown-link-active-bg: #f8971c !important;
    --bs-dropdown-link-hover-bg: rgba(248, 151, 28, 1) !important;
}

@media screen and (max-width:805px) {
    #sidebar {
        z-index: 1000;
        min-width: max-content;
        /* min-height: 200vh !important; */
    }

    .btn-sidebar {
        display: block;
    }
}

@media screen and (max-width:515px) {
    .nav-time {
        display: none !important;
    }

    .name-designation {
        display: none;
    }
}

.sidebar-icon-text{
    font-size: 12px !important;
    font-weight: bold;
}

.navbar-icon {
    border-radius: 200px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}