.react-datepicker__input-container>input {
    border: 2px solid #000;
    width: 180px !important;
    text-align: center;
    margin: 0 !important;
    background-color: #fff;
    color: #000;
    font-size: 30px;
    font-weight: 500;
    border-radius: 0.375rem;
}