.react-datepicker__input-container>input:hover {
    width: 180px !important;
}

.btn-round {
    background-color: rgba(248, 151, 28, 0.3);
    border: 2px solid #F8971C;
}

.btn-round:hover,
.btn-round:focus {
    background-color: rgba(248, 151, 28, 0.3);
}

/* tr.table-head th {
    background-color: #ffe4bc;
    
} */

.approved-hours {
    background-color: #F8971C !important;
    z-index: 0 !important;
}

.pending-hours {
    background-color: #000 !important;
    color: #fff !important;
    border: none !important;
}

.pending-hours:hover {
    background-color: rgba(248, 151, 28, 0.8);
    border: 1px solid black;
}

.thead-tag {
    text-align: center;
    position: relative;
    z-index: 998;
}

.date-picker {
    z-index: 999;
}

.table-container {
    border: 1px solid #F8971C;
    border-radius: 0.375rem;
    min-height: 30vh;
    max-height: 70vh;
    overflow: scroll;
    margin: 0;
    width: 100%;
}

.table-head-extHourReq {
    background-color: #ffe4bc !important;
    height: 10vh;
    /* background: white; */
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(248, 151, 28, 0.8);
}

tbody>tr:last-child {
    border-bottom: none !important;
}

.back-nav-btn {
    color: #000;
    font-weight: bold;
    font-size: 1.5em;
    font-style: unset;
    padding: 0 0.5rem;
    border: 2px solid #F8971C !important;
    width: max-content;
    border-radius: 0.375rem;

}

.back-nav-btn:hover {
    background-color: rgba(248, 151, 28, 0.8) !important;
    color: #000;
}