.date-container {
    width: max-content;
    gap: 0.4rem;
}

.disp-salary {
    background-color: #F8971C;
    padding: 3px 5px;
    border-radius: 0.375rem;
    /* border: 2px solid #F8971C; */
    cursor: pointer;
    color: #fff;
    width: max-content;
}

.select-date {
    width: 100%;
}

.user-info {
    width: 100%;
    font-weight: bold;
}

.cell {
    background-color: #F8971C;
    width: 100%;
    min-width: max-content;
    /* padding: 0 2rem; */
    text-align: center;
    border: 1px solid white;
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-info{
    display: flex;
    justify-content: flex-start;
    height: max-content;
    align-items: center;
}

.element{
    width:100%;
    display:flex;
    flex-direction: column;
}

.toogle-btn-user{
    background-color: #F8971C;
    border:none;
    cursor: pointer;
    color: #fff;
}

.toogle-btn-user:hover{
    background-color: rgba(248, 152, 27, 0.8);
    color: #000; 
}

@media screen and (max-width:1125px){
    .user-info{
        flex-wrap: wrap;
    }
    .cell{
        min-height: 1vh;
    }
}

.table-salary-hover:hover{
    background-color: #F8971C;
}

.table-salary-th{
    padding: 3px;
}