.login {
    background-color: rgba(248, 151, 28, 0.9);
    background-image: url('../../assets/images/loginBackground.svg');
}

.tl {
    background-image: url('../../assets/images/loginBackground.svg');
    height: 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #000;
    clip-path: polygon(50% 0, 100% 0, 100% 70%, 50% 100%, 0 100%, 0 29%);
}

.logo-auth {
    float: right;
    width: 144px;
    height: 150px !important;
}

h2 {
    text-align: center;
    color: #fff !important;
}

.login-form {
    border: 1px solid #F8971C;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    min-width: 400px;
    max-width: 100%;
}

.input-data {
    color: #fff !important;
    border: none !important;
    background-color: rgba(248, 151, 28, 0.3) !important;
    border-radius: 50px !important;
    position: relative !important;
}

.input-data:focus {
    background-color: rgba(248, 151, 28, 0.3) !important;
}

/* .pwd {
    position: absolute;
    left: 390px;
} */

.btn-primary {
    background-color: #F8971C !important;
    border-radius: 20px !important;
    font-size: 15px !important;
    font-weight: bolder !important;
    padding: 5px 20px !important;
    border: none !important;
}

.btn-primary:hover {
    background-color: rgba(248, 151, 28, 0.8);
}

a {
    text-decoration: none !important;
}

input::placeholder {
    color: #fff;
    opacity: 1;
}

.back-navbtn {
    color: #fff;
    font-weight: bold;
    font-size: 1.5em;
    font-style: unset;
    padding: 0 0.3rem;
}

.back-navbtn:hover {
    color: rgba(248, 151, 28, 0.8);
}

/* @media screen and (max-width:930px) {
    .pwd {
        position: absolute;
        left: 350px;
    }

}
@media screen and (max-width:420px) {
    .pwd {
        position: absolute;
        left: 300px;
    }

}
@media screen and (max-width:375px) {
    .pwd {
        position: absolute;
        left: 260px;
    }

} */

.login-spinner {
    color: #F8971C;
    font-size: 25px;
    font-weight: lighter;
}

.resetpass-btn {
    min-width: max-content !important;
    font-style: italic;
    font-weight: bold;
    color: #F8971C;
}

.resetpass-btn:hover {
    color: #fff;
}